import React, { useRef, useEffect, useState } from "react";
import VelocitySlider from "./VelocitySlider";
import Slideshow from "./Slideshow";
import ShadowSkills from "./ShadowSKills";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const Skills = () => {
  return (
    <div className="">
      <div
        id="skills"
        className="relative flex w-full h-full items-center  flex-col justify-center mb-[100px] mt-28 lg:mt-52"
      >
        <div className="w-full flex items-center flex-col ">
          <h1 className="text-5xl sm:text-9xl text-banana font-black pb-3">
            Skills
            <span className="text-xl opacity-60 hidden sm:inline-block"></span>
          </h1>
          <h1 className="text-2xl text-left text-white font-light pb-10">
            What I choose to be good at
          </h1>
        </div>

        {/* <hr className="h-2 w-full bg-white pl-10" /> */}

        <VelocitySlider />
        <Slideshow />
        <ShadowSkills />

        {/* <hr className='h-2 w-full bg-banana pl-10'/> */}
      </div>
    </div>
  );
};

export default Skills;
