import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

const ParticlesBG = () => {
    const ParticlesInit = useCallback(async engine => {
        // console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const ParticlesLoaded = useCallback(async container => {
        // await console.log(container);
    }, []);

    return (
        <Particles
        className="-z-10 fixed hidden sm:block"
            id="tsparticles"
            init={ParticlesInit}
            loaded={ParticlesLoaded}
            options={{
              "particles": {
                "number": {
                  "value": 30,
                  "density": {
                    "enable": false,
                    "value_area": 1000
                  }
                },
                "color": {
                  "value": "#fff856"
                },
                "shape": {
                  "type": "circle",
                  "stroke": {
                    "width": 0,
                    "color": "#000000"
                  },
                  "polygon": {
                    "nb_sides": 5
                  },
                  "image": {
                    "src": "img/github.svg",
                    "width": 100,
                    "height": 100
                  }
                },
                "opacity": {
                  "value": 0.3586057502953915,
                  "random": false,
                  "anim": {
                    "enable": false,
                    "speed": 0.2,
                    "opacity_min": 0.1,
                    "sync": false
                  }
                },
                "size": {
                  "value": 1.9385584875935393,
                  "random": true,
                  "anim": {
                    "enable": false,
                    "speed": 0.1,
                    "size_min": 1.5953670540749656,
                    "sync": false
                  }
                },
                "line_linked": {
                  "enable": true,
                  "distance": 260.94242303078775,
                  "color": "#ffffff",
                  "opacity": 0.3277089164334269,
                  "width": 1.119552179128349
                },
                "move": {
                  "enable": true,
                  "speed": 1.4,
                  "direction": "top",
                  "random": false,
                  "straight": false,
                  "out_mode": "out",
                  "bounce": false,
                  "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                  }
                }
              },
              "interactivity": {
                "detect_on": "window",
                "events": {
                  "onhover": {
                    "enable": true,
                    "mode": "grab"
                  },
                  "onclick": {
                    "enable": true,
                    "mode": "repulse"
                  },
                  "resize": true
                },
                "modes": {
                  "grab": {
                    "distance": 200,
                    "line_linked": {
                      "opacity": 1
                    }
                  },
                  "bubble": {
                    "distance": 200,
                    "size": 5,
                    "duration": 2,
                    "opacity": 8,
                    "speed": 2
                  },
                  "repulse": {
                    "distance": 100,
                    "duration": 1
                  },
                  "push": {
                    "particles_nb": 4
                  },
                  "remove": {
                    "particles_nb": 2
                  }
                }
              },
              "retina_detect": true
            
            }}
        />
    );
};

export default ParticlesBG