import face from '../../assets/image/animated-me.webp'

const AnimeMe = () => {
  return (
    <div className='absolute bottom-0 w-full flex justify-center'>
        <img className='hugeshadow h-[60vh] lg:h-[75vh] xl:h-[80vh] object-cover' src={face} alt="" />
    </div>
  )
}

export default AnimeMe