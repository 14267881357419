
import AnimeMe from './AnimeMe'
import NameAnimation from './NameAnimation'
import ShowDetail from './ShowDetail'
const NewHome = () => {
  return (
    <div className="bg-background h-screen w-full relative shadow-2xl">
        <NameAnimation name='Migel Sastrawan Sugiarto'/>
        <AnimeMe />
        <ShowDetail />
    </div>
  )
}

export default NewHome