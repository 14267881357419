import { useref } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";

import { Autoplay, EffectCoverflow, Navigation } from "swiper";
import Logos from "../../images";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

const slideshow = () => {
  // console.log(Logos)
  const LogoNames = [
    "HTML",
    "C",
    "Bootstrap",
    "Greensock",
    "Figma",
    "CSS",
    "Javascript",
    "React",
    "Laravel",
    "Tailwind",
    "Sass",
    "Framer-motion",
  ];

  return (
    <div className="w-full h-52 bg-gradient-to-tr sm:hidden">
      <Swiper
        className="w-full mb-20 "
        navigation={true}
        modules={[Autoplay, Navigation]}
        breakpoints={{
          320: { slidesPerView: 2, spaceBetween: 40 },
          480: { slidesPerView: 2, spaceBetween: 40 },
          768: { slidesPerView: 3, spaceBetween: 40 },
          1024: { slidesPerView: 3, spaceBetween: 40 },
        }}
        speed={3200}
        loop={true}
        // freeMode={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
      >
        {Logos.map((Logo, index) => (
          <SwiperSlide
            key={index}
            virtualIndex={index}
            className="min-h-max"
          >
            <div className="relative flex items-center justify-center w-full align-middle">
              <motion.h1 className="absolute text-2xl font-black text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 -z-50">
                {LogoNames[index]}
              </motion.h1>
              <motion.img
                className="p-10 bg-white lg:p-16 rounded-xl w-96"
                src={Logo}
                transition={{ duration: 0.3 }}
                whileHover={{ opacity: 0.1 }}
              ></motion.img>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default slideshow;
