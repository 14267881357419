import Logos from "../../images";
import { useRef } from "react";
import { motion, useScroll, useTransform, useMotionValueEvent } from "framer-motion";
import FirstSlide from "./FirstSlide";
import SeconSlide from "./SecondSlide";
const VelocitySlider = () => {

  return (
    <section className="hidden sm:flex flex-col justify-center py-10 gap-[40px]">
     <FirstSlide/>
     <SeconSlide/>
    </section>
  );
};

export default VelocitySlider;
