import { motion, useMotionValue, useVelocity } from "framer-motion";
import { useEffect } from "react";

const NameAnimation = ({ name }) => {
  const x = useMotionValue(0)
  const xVelocity = useVelocity(x)
  const xAcceleration = useVelocity(xVelocity)

  return (
    <motion.div className="absolute flex flex-row gap-1 w-full h-72 bottom-0 bg-none overflow-hidden text-[12vh] lg:text-[20vh] whitespace-nowrap pb-16 items-end z-10 text-white bhugeshadow "
      
    >
      <motion.h1
        className="absolute noway" 
        initial={{x:"0%"}}
        animate={{ x: "-220%" }}
        transition={{ ease: "linear", repeat: Infinity, duration: 20, }}
        
      >
        {name}
      </motion.h1>
      <motion.h1
        className="absolute noway"
        initial={{x:"110%"}}
        animate={{ x: "-110%" }}
        transition={{ ease: "linear", repeat: Infinity, duration: 20, }}
        style={{x}}
      >
        {name}
      </motion.h1>
      <motion.h1
        className="absolute noway"
        initial={{x:"110%"}}
        animate={{ x: "-110%" }}
        transition={{ ease: "linear", repeat: Infinity, duration: 20, delay:10 }}
      >
        {name}
      </motion.h1>
    </motion.div>
  );
};

export default NameAnimation;
