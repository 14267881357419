import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import Instagram from "../../assets/image/contact-logo/instagram.png";
import Linkedin from "../../assets/image/contact-logo/linkedin.png";

const footer = () => {
  return (
    <div
      id="contact"
      className="flashy relative w-full h-full bg-black bg-opacity-30"
    >
      <div className="">
        {/* <div className="absolute w-10 border-b-[2px] left-10 border-banana"></div> */}
        {/* <h1 className="text-4xl text-white">Thank you for Visiting!</h1> */}
      </div>
      {/* delete this just this line lol */}
      <div className="text-left w-full h-full px-8 sm:px-16 py-5 text-white">
        <div className="flex flex-row justify-between  border-banana pt-10 pb-8 w-full h-full">
          <div className="flex flex-col sm:flex-row justify-between  w-full h-full">
            <h1 className="text-xl md:text-4xl">Let's get in touch!</h1>
            <div className="flex items-center ">
              <AiOutlineMail /> 
              <h1> : migelssugiarto@gmail.com</h1>
            </div>
          </div>

          {/* <div>
            <h1 className="text-4xl">Social Media</h1>
          </div> */}
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default footer;
