import {motion} from 'framer-motion'
import face from "../../assets/image/profile-image.png";

const Aboutmeimage = () => {
  return (
    <motion.div
        className="z-50 block w-64 shadow-2xl sm:w-64 rounded-xl lg:w-2/6 aboutMePhoto bg-banana "
        initial={{ y:40 }}
        // animate={{ y: 40, opacity: 1 }}
        transition={{ repeat: Infinity, repeatType: "mirror", duration: 3.5 }}
      >
        <img
          src={face}
          alt=""
          className="rounded-xl mx-auto w-facepic"
        />
      </motion.div>
  );
};

export default Aboutmeimage;
