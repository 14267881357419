// import logo from './logo.svg';
import Navbar from "./components/navbar/navbar";
import Home from "./components/home/Home";
import Aboutme from "./components/aboutme/aboutme";
import Logo from "./components/logo/logo";
import Cursor from "./components/cursor";
import Skills from "./components/skills/Skills";
import Experience from "./components/Experience/Experience";
import NewHome from "./components/NewHome/NewHome";
import ParticlesBG from "./components/particlesBG";
import Footer from "./components/Footer/Footer";

import { motion } from "framer-motion";

function App() {
  return (
    <div className="block w-full h-auto text-center  overflow-hidden">
      {/* <Logo /> */}
      {/* <ParticlesBG /> */}
      <NewHome />
      {/* <Home /> */}
      <Aboutme />
      <Skills />

      {/* <Experience /> */}
      <Footer />
      {/* <Cursor /> */}
      {/* <Navbar /> */}
    </div>
  );
}

export default App;
