import HTML from "./assets/image/skill-logos/html.png"
import Figma from "./assets/image/skill-logos/figma.png"
import CSS from "./assets/image/skill-logos/css.png"
import Javascript from "./assets/image/skill-logos/javascript.png"
import React from "./assets/image/skill-logos/react.png"
import Laravel from "./assets/image/skill-logos/laravel.png"
import C from "./assets/image/skill-logos/c.png"
import Tailwind from "./assets/image/skill-logos/tailwind.png"
import Sass from "./assets/image/skill-logos/sass.png"
import Bootstrap from "./assets/image/skill-logos/bootstrap.png"
import Framer from "./assets/image/skill-logos/framer-motion.png"
import Greensock from "./assets/image/skill-logos/greensock.png"


export default [HTML, C, Bootstrap, Greensock, Figma, CSS, Javascript, React, Laravel , Tailwind, Sass, Framer ];