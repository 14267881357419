import React from "react";
import Aboutmeimage from "./Aboutmeimage";
import Wavedecor from "./Wavedecor";
import Staticwavedecor from "./Staticwavedecor";
import Aboutmedesc from "./Aboutmedesc";
import ShadowAboutMe from "./ShadowAboutMe";
import { useRef, useEffect, useLayoutEffect } from "react";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";

const Aboutme = () => {
  const wavescreen = useRef(null);
  // const { scrollY } = useScroll({});
  let sh = 0;


  return (
      <motion.div
        id="about"
        className={`relative z-50  h-full flex flex-col bg-opacity-30 bg-black items-center lg:items-start lg:justify-center min-h-full lg:h-screen max-w-full py-32 opacity-100  lg:flex-row  gap-7 px-6 sm:px-14 lg:gap-20`}
        initial={{ y: 0 }}
      >
        {/* <Wavedecor /> */}
        <Aboutmeimage />
        <Aboutmedesc />
        
        {/* <Staticwavedecor /> */}
      </motion.div> //
  );
};

export default Aboutme;
