import { motion, AnimatePresence, useInView, delay } from "framer-motion";
import { useRef, useEffect } from "react";
import Otherworks from "./Otherworks";

const Aboutmedesc = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: "some" });
  // useEffect(() => {
  //   console.log("Element is in view: ", isInView);
  // }, [isInView]);
  const container = {
    hidden: {},
    show: {
      transition: {
        duration: 2,
        type: "tween",
        staggerChildren: 0.05,
        staggerDirection: 1,
        when: "beforeChildren",
        delayChildren: 0.15,
        repeat: 1,
      },
    },
  };

  const item = {
    hidden: { y: 50 },
    show: { y: 0 },
  };

  const fadein = {
    hidden: { opacity: 0, y: 15 },
    show: { opacity: 1, y: 0 },
  };
  const title = "About Me".split("");
  return (
    <motion.div className="h-full lg:h-full flex flex-col items-center sm:items-start lg:items-start z-50 mt-10 lg:mt-40 justify-start lg:w-7/12 align-center lg:pt-0">
      <motion.div
        ref={ref}
        className="flex flex-col sm:flex-row sm:items-end sm:gap-3"
      >
        <AnimatePresence>
          <motion.h1
            className="clipPath text-3xl sm:text-6xl text-left lg:text-left opacity-100 font-black text-banana "
            variants={container}
            initial="hidden"
            whileInView="show"
          >
            {title.map((letter, index) => {
              return (
                <motion.span
                  key={index}
                  variants={item}
                  style={{ display: "inline-block" }}
                >
                  {letter}
                </motion.span>
              );
            })}
          </motion.h1>
          
        </AnimatePresence>
        <span className="text-xl opacity-60 text-white"> [ əˈbout mē ]</span>
      </motion.div>

      <hr className="h-[2px] block w-full bg-white border-none mt-3" />

      <motion.p
        className="text-justify w-full mt-3 text-xl lg:text-left sm:text-2xl text-white   pb-3"
        variants={fadein}
        initial="hidden"
        whileInView="show"
        transition={{ duration: 0.75 }}
      >
        I am passionate about creating visually stunning and user-friendly
        websites that provide a seamless user experience. I am constantly
        seeking new opportunities to expand my skillset and bring enthusiasm and
        dedication to every project
      </motion.p>

      {/* <Otherworks /> */}
    </motion.div>
  );
};

export default Aboutmedesc;
