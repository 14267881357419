import Logos from "../../images";
import { useRef } from "react";
import {
  motion,
  useScroll,
  useTransform,
  useMotionValueEvent,
} from "framer-motion";

const FirstSlide = () => {
  const ref = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end start", "start end"],
  });

  let x = useTransform(scrollYProgress, [1, 0], ["100px", "-100px"]);
  return (
    <motion.div
      className="flex flex-row "
      style={{ x }}
      initial={{ scale: 1.18 }}
      ref={ref}
    >
      {Logos.map((Logo, index) =>
        index >= 6 ? (
          <div className=" bg-opacity-30 bg-black m-5 ">
            <div
              key={index}
              className="bg-white p-10 m-3 "
            >
              <motion.img
                className="flex min-w-[100px] h-auto"
                src={Logo}
                alt=""
              />
            </div>
          </div>
        ) : null
      )}
    </motion.div>
  );
};

export default FirstSlide;
