import Linkedin from "../../assets/image/contact-logo/linkedin.png";
import Resume from "../../assets/image/contact-logo/ResumeLogo.png";
import Instagram from "../../assets/image/contact-logo/instagram.png";
import Resumeurl from "../../assets/Migel S. Sugiarto Resume.pdf";

import arrow from "../../assets/image/contact-logo/back-drawn-arrow.png";

import Typewriter from "typewriter-effect";

const ShowDetail = () => {
  return (
    <div className=' w-full h-full flex flex-col items-center p-10 gap-14 sm:gap-12 lg:gap-20 '>
      <div className='flex flex-row w-full  justify-between '>
        <div className='flex flex-row w-full justify-between sm:justify-start gap-5'>
          <a
            href='https://www.linkedin.com/in/migel-sastrawan-sugiarto-707004255?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BFJrKqVQAQKC9ZJC30Vh22A%3D%3D'
            target='_blank'
            rel='noopener noreferrer'>
            <div className='button flex flex-row gap-2 items-center justify-center w-28 sm:w-32 bg-banana hover:bg-[#fffd9c] active:bg-[#f2f2df] py-1 px-2 sm:py-1 sm:px-2 rounded-md text-sm sm:text-base '>
              <img className='w-4 sm:w-5 object-cover' src={Linkedin} alt='' />
              <h1 className='text-background pt-1'>Linked In</h1>
              
            </div>
          </a>
          {/* <a href={Resumeurl} target='_blank' rel='noopener noreferrer'>
            <div className='flex flex-row gap-2 items-center justify-center w-28 sm:w-32 bg-banana py-1 px-2 sm:py-1 sm:px-2 rounded-md text-sm sm:text-base  hover:bg-[#fffd9c] active:bg-[#f2f2df]'>
              <img className='w-4 sm:w-5 object-cover' src={Resume} alt='' />
              <h1 className='text-background pt-1'>Resume</h1>
            </div>
          </a> */}
        </div>
        <div>
          <a className='hidden sm:block' href='https://www.instagram.com/migelss01/' target="_blank" rel="noopener noreferrer ">
            <div className=' flex flex-row gap-2 items-center justify-center w-28 sm:w-32 bg-banana py-1 px-2 sm:py-1 sm:px-2 rounded-md text-sm sm:text-base  hover:bg-[#fffd9c] active:bg-[#f2f2df]'>
              <img className='w-4 sm:w-5 object-cover' src={Instagram} alt='' />
              <h1 className='text-background pt-1'>Instagram</h1>
            </div>
          </a>
        </div>
      </div>

      <div className='flex flex-col gap-3 lg:w-full'>
        <div className='text-2xl font-normal noway sm:text-4xl lg:hidden text-banana'>
          <Typewriter
            options={{
              autoStart: true,
              loop: true,
              delay: 40,
              pauseFor: 3000,
              strings: [
                "Hello",
                "你好",
                "Bonjour",
                "Guten tag",
                "Hola",
                "ٱلسَّلَامُ عَلَيْكُمْ",
                "Olá",
                "こんにちは",
                "Hola",
                "안녕하세요",
                "Halo",
              ],
            }}
          />
        </div>
        <div
          className='relative w-full  text-3xl lg:flex lg:flex-row
        lg:justify-between sm:text-4xl xl:text-5xl 2xl:text-6xl text-white font-light '>
          <h1 className=' noway font-light text-left hidden lg:block'>
            A Computer Science <br /> undergraduate at <br />
            Bina Nusantara
          </h1>
          <h1 className=' noway font-light lg:text-right whitespace-nowrap'>
            A junior frontend <br /> web developer
          </h1>
          <div className='absolute -right-10 -bottom-16 lg:hidden'>
            <img className='w-10' src={arrow} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowDetail;
