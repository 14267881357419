import { useRef } from "react";
import {
  motion,
  useScroll,
  useTransform,
  useMotionValueEvent,
} from "framer-motion";

const ShadowSkills = () => {
  const ref = useRef(null);

  const { scrollYProgress, scrollY } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  // useMotionValueEvent(scrollYProgress, "change", (latest) => {
  //   console.log("Page scroll: ", latest);
  // });

  let y = useTransform(scrollYProgress, [1, 0], ["-25%", "25%"]);
  let opacity = useTransform(
    scrollYProgress,
    [0, 0.25,0.5, 0.75, 1],
    [0, 0, 0.1,0, 0]
  );
  return (
    <div
      className="absolute  -z-50 -top-[140px] left-[50%] -translate-x-1/2 flex sm:flex text-white"
      ref={ref}
    >
      <motion.h1
        className="sm:text-[270px] font-black text-banana blur-sm opacity-10"
        initial={{ scale: 1.6 }}
        style={{ y, opacity }}
      >
        Skills
      </motion.h1>
    </div>
  );
};

export default ShadowSkills;
